import request, { ApiResponse, RequestError } from '@/utils/request';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import useMerchandisingStrategyExperiment from '../experiments/use-merchandising-strategy/use-merchandising-strategy-experiment';
import { CouponDetails } from '@/types/coupon';

type CouponDetailsResponse = Omit<CouponDetails, 'code'> & { id: string };

const fetchCouponData = async (couponId: string) => {
  const { data: response } = await request<ApiResponse<CouponDetailsResponse>>({
    url: `/v1/financials/coupon/${couponId}`,
  });

  const coupon = response.data;
  return {
    code: coupon.id,
    ...coupon,
  };
};

const usePromoCodeData = () => {
  const router = useRouter();
  const { isMerchandisingStrategyExperiment } =
    useMerchandisingStrategyExperiment();

  return useQuery<CouponDetails, RequestError>(
    'promo-code',
    () => fetchCouponData(router.query.code as string),
    { enabled: !!router.query.code && !!isMerchandisingStrategyExperiment },
  );
};

export default usePromoCodeData;
