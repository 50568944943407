import useDcsFlag from '@/hooks/use-dcs-flag';
import DcsFlags from '@/types/dcs-flags';
import { MerchandisingConfigFlag } from '@/types/flags';
import { CouponDetails } from '@/types/coupon';

const useMerchandisingConfig = (promoCode?: CouponDetails) => {

  const { data, isLoading } = useDcsFlag<MerchandisingConfigFlag>({
    flag: DcsFlags.MerchandisingConfig,
    params: {
      coupon_type: promoCode?.discount_type,
    },
    options: {
      enabled: !!promoCode,
    },
  });

  return { data, isLoading };
};

export { useMerchandisingConfig };
